// import { useState } from "react";
// import { Tabs, Tab } from 'react-bootstrap-tabs';
// import ExcelUploader from "../excelUpload/excelUpload";
// import SoftDemandPreview from "../preview/SoftDemandPreview";
// import InventoryPreview from "../preview/InventoryPreview";
// import ProductionSchedulePreview from "../preview/ProductionSchedulePreview";
// import PlanningPreview from "../preview/PlanningPreview";
// import MaterialsPreview from "../preview/MaterialsPreview"
// const Uploads = () => {
//     const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
//     const [showPreviewTable, setShowPreviewTable] = useState(false);
//     const [data, setData] = useState([]);
//     const [tabKey, setTabKey] = useState(0); // State to keep track of the tab key

//     const handleTabChange = (newKey) => {
//         setTabKey(newKey); // Update the tab key when the tab changes
//         setShowPreviewTable(false);
//         setData([]);
//     };

//     const responseData = (data) => {
//         if (data.status.code === "SUCCESS") {
//             setData(data.data);
//             setShowPreviewTable(true);
//         }
//     }
//     return (
//         <div className="farmMaster">
//             <div class="row mb-2">
//                 <div class="col-sm-6">
//                     <h2 class="m-0 text-dark">Uploads</h2>
//                 </div>
//             </div>
//             <div className="card">
//                 <div class="card-body">
                    
//                         <Tabs activeHeaderStyle={{ background: 'transparent' }} onSelect={() => handleTabChange(Date.now())}>
//                             <Tab label="Upload Materials" eventKey="Upload Materials">
//                                 <div className="row">
//                                     <div className="col">
//                                         <ExcelUploader key={tabKey} uploadType='Materials' filePath='/Material.xlsx' responseDataSend={responseData}></ExcelUploader>
//                                     </div>
//                                 </div>
//                                 {showPreviewTable && (<MaterialsPreview tableData={data}></MaterialsPreview>)}
//                             </Tab>
//                             {/* <Tab label="Upload Planning" eventKey="Upload Planning">
//                                 <div className="row">
//                                     <div className="col">
//                                         <ExcelUploader key={tabKey} uploadType='Planning' filePath='/PlanningUpload.xlsx' responseDataSend={responseData}></ExcelUploader>
//                                     </div>
//                                 </div>
//                                 {showPreviewTable && (<PlanningPreview tableData={data}></PlanningPreview>)}
//                             </Tab>
//                             <Tab label="Upload Soft Demand" eventKey="Upload Soft Demand">
//                                 <div className="row">
//                                     <div className="col">
//                                         <ExcelUploader key={tabKey} uploadType='Soft Demand' filePath='/SoftDemand.xlsx' responseDataSend={responseData}></ExcelUploader>
//                                     </div>
//                                 </div>
//                                 {showPreviewTable && (<SoftDemandPreview tableData={data}></SoftDemandPreview>)}
//                             </Tab> */}
//                         </Tabs>
                    
//                     {
//                         partyType === 'Supplier' &&
//                         <Tabs activeHeaderStyle={{ background: 'transparent' }}>
//                             <Tab label="Upload Production Schedule" eventKey="Upload Production Schedule">
//                                 <div className="row">
//                                     <div className="col">
//                                         <ExcelUploader key={tabKey} uploadType='Production Schedule' filePath='/ProductionSchedule.xlsx' responseDataSend={responseData}></ExcelUploader>
//                                     </div>
//                                 </div>
//                                 {showPreviewTable && (<ProductionSchedulePreview tableData={data}></ProductionSchedulePreview>)}
//                             </Tab>
//                             <Tab label="Upload Dispatch Info" eventKey="Upload Dispatch Info" disabled>
//                                 <div className="row">
//                                     <div className="col">
//                                         <ExcelUploader uploadType="Dispatch Info" filePath='/DispatchInfo.xlsx'></ExcelUploader>
//                                     </div>
//                                 </div>
//                             </Tab>
//                             <Tab label="Upload Inventory" eventKey="Upload Inventory">
//                                 <div className="row">
//                                     <div className="col">
//                                         <ExcelUploader key={tabKey} uploadType="Inventory" filePath='/SupplierInventory.xlsx' responseDataSend={responseData}></ExcelUploader>
//                                     </div>
//                                 </div>
//                                 {showPreviewTable && (<InventoryPreview tableData={data}></InventoryPreview>)}
//                             </Tab>
//                         </Tabs>
//                     }
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Uploads;

import { useState } from "react";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import ExcelUploader from "../excelUpload/excelUpload";
import SoftDemandPreview from "../preview/SoftDemandPreview";
import InventoryPreview from "../preview/InventoryPreview";
import ProductionSchedulePreview from "../preview/ProductionSchedulePreview";
import PlanningPreview from "../preview/PlanningPreview";
import MaterialsPreview from "../preview/MaterialsPreview"
const Uploads = () => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const [showPreviewTable, setShowPreviewTable] = useState(false);
    const [data, setData] = useState([]);
    const [tabKey, setTabKey] = useState(0); // State to keep track of the tab key

    const handleTabChange = (newKey) => {
        setTabKey(newKey); // Update the tab key when the tab changes
        setShowPreviewTable(false);
        setData([]);
    };

    const responseData = (data) => {
        if (data.status.code === "SUCCESS") {
            setData(data.data);
            setShowPreviewTable(true);
        }
    }
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Uploads</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    {
                        partyType !== 'Supplier' &&
                        <Tabs activeHeaderStyle={{ background: 'transparent' }} onSelect={() => handleTabChange(Date.now())}>
                            <Tab label="Upload Materials" eventKey="Upload Materials">
                                <div className="row">
                                    <div className="col">
                                        <ExcelUploader key={tabKey} uploadType='Materials' filePath='/Material.xlsx' responseDataSend={responseData}></ExcelUploader>
                                    </div>
                                </div>
                                {showPreviewTable && (<MaterialsPreview tableData={data}></MaterialsPreview>)}
                            </Tab>
                            <Tab label="Upload Planning" eventKey="Upload Planning">
                                <div className="row">
                                    <div className="col">
                                        <ExcelUploader key={tabKey} uploadType='Planning' filePath='/PlanningUpload.xlsx' responseDataSend={responseData}></ExcelUploader>
                                    </div>
                                </div>
                                {showPreviewTable && (<PlanningPreview tableData={data}></PlanningPreview>)}
                            </Tab>
                            <Tab label="Upload Soft Demand" eventKey="Upload Soft Demand">
                                <div className="row">
                                    <div className="col">
                                        <ExcelUploader key={tabKey} uploadType='Soft Demand' filePath='/SoftDemand.xlsx' responseDataSend={responseData}></ExcelUploader>
                                    </div>
                                </div>
                                {showPreviewTable && (<SoftDemandPreview tableData={data}></SoftDemandPreview>)}
                            </Tab>
                        </Tabs>
                    }
                    {
                        partyType === 'Supplier' &&
                        <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                            <Tab label="Upload Production Schedule" eventKey="Upload Production Schedule">
                                <div className="row">
                                    <div className="col">
                                        <ExcelUploader key={tabKey} uploadType='Production Schedule' filePath='/ProductionSchedule.xlsx' responseDataSend={responseData}></ExcelUploader>
                                    </div>
                                </div>
                                {showPreviewTable && (<ProductionSchedulePreview tableData={data}></ProductionSchedulePreview>)}
                            </Tab>
                            <Tab label="Upload Dispatch Info" eventKey="Upload Dispatch Info" disabled>
                                <div className="row">
                                    <div className="col">
                                        <ExcelUploader uploadType="Dispatch Info" filePath='/DispatchInfo.xlsx'></ExcelUploader>
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Upload Inventory" eventKey="Upload Inventory">
                                <div className="row">
                                    <div className="col">
                                        <ExcelUploader key={tabKey} uploadType="Inventory" filePath='/SupplierInventory.xlsx' responseDataSend={responseData}></ExcelUploader>
                                    </div>
                                </div>
                                {showPreviewTable && (<InventoryPreview tableData={data}></InventoryPreview>)}
                            </Tab>
                        </Tabs>
                    }
                </div>
            </div>
        </div>
    )
}

export default Uploads;