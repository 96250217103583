import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from 'react';

const CustomerMastersList = () => {

    const columns = [
        {
            name: "Customer ID",
            selector: "customerID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Customer Name",
            selector: "customerName",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "No Of Sites",
            selector: "noOfSites",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Admin Mail ID",
            selector: "adminMailID",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Admin Contact",
            selector: "adminContact",
            sortable: true,
            minWidth: '60px'
        },
        {
            name: "Address",
            selector: "address",
            sortable: true,
            minWidth: '50px'
        },
        {
            name: "Action",
            selector: "",
            sortable: true,
            minWidth: '150px'
        }
    ];

    const [data, setData] = useState([]);

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
        </>
    )

}

export default CustomerMastersList;