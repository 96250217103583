import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { useForm, Controller } from "react-hook-form";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import MaterialMasterList from "./MaterialMasterList"
const MaterialMaster = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [rows, setRows] = useState([{ id: Date.now() }]);

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now() }]);
    };

    const handleRemoveRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const onSubmit = (data, e) => { }
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Material Master</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Create Material Master">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material ID</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Material ID"
                                                name="materialID"
                                                {...register("materialID", {
                                                    required: "Please enter Material ID",

                                                })}
                                            />
                                            {errors.materialID && <span className="err-msg">{errors.materialID.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material Category</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Material Category"
                                                name="materialCategory"
                                                {...register("materialCategory", {
                                                    required: "Please enter Material Category",

                                                })}
                                            />
                                            {errors.materialCategory && <span className="err-msg">{errors.materialCategory.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material Name</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Material Name"
                                                name="materialName"
                                                {...register("materialName", {
                                                    required: "Please enter Material Name",
                                                })}
                                            />
                                            {errors.materialName && <span className="err-msg">{errors.materialName.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material Description</label>
                                            <input type="email" className="form-control form-control-sm" placeholder="Enter Material Description"
                                                name="materialDescription"
                                                {...register("materialDescription", {
                                                    required: "Please enter Material Description",
                                                })}
                                            />
                                            {errors.materialDescription && <span className="err-msg">{errors.materialDescription.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <strong>Supplier Information</strong>
                                    </div>
                                    {rows.map((row, index) => (
                                        <div className='col-md-12'>
                                            <div className='row m-3'>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor={`supplierID_${index}`}>Supplier ID</label>
                                                        <select
                                                            className="form-control form-control-sm"
                                                            name={`supplierID_${index}`}
                                                            {...register(`supplierID_${index}`, {
                                                                required: "Please select Supplier ID",
                                                            })}
                                                        >
                                                            <option value="">-Select-</option>
                                                        </select>
                                                        {errors[`supplierID_${index}`] && <span className="err-msg">{errors[`supplierID_${index}`].message}</span>}
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor={`leadDays_${index}`}>Lead Days</label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            placeholder="Enter Lead Days"
                                                            name={`leadDays_${index}`}
                                                            {...register(`leadDays_${index}`, {
                                                                required: "Please enter Lead Days",
                                                            })}
                                                        />
                                                        {errors[`leadDays_${index}`] && <span className="err-msg">{errors[`leadDays_${index}`].message}</span>}
                                                    </div>
                                                </div>
                                                <div className='col-md-1' id="addMore">
                                                    {index === rows.length - 1 && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-block btn-outline-success btn-sm"
                                                            onClick={handleAddRow}
                                                        >
                                                            <IoMdAdd /> Add
                                                        </button>
                                                    )}
                                                    {index !== rows.length - 1 && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-block btn-outline-danger btn-sm"
                                                            onClick={() => handleRemoveRow(row.id)}
                                                            style={{ width: "90px", whiteSpace: "nowrap" }}
                                                        >
                                                            <IoMdRemove /> Remove
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                        <input type="reset" value="Reset" class="btn btn-secondary newBtn" />
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab label="Materials List">
                            <MaterialMasterList></MaterialMasterList>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default MaterialMaster;