import React from "react";
import logo from "./logo.svg";
import "./App.css";

import TopHeader from "./components/layout/TopHeader";
import TopFooter from "./components/layout/TopFooter";
import Dashboard from "./components/pages/Dashboard";
import Login from './components/pages/Login';
import Inward from "./components/pages/Inward";
import Outwards from "./components/pages/Outward";
import DamagedInventory from "./components/pages/DamagedInventory";
import Inventory from "./components/pages/Inventory";
import GoodsIssuance from "./components/pages/GoodsIssuance";
import OutwardReturns from "./components/pages/OutwardReturns";
import Uploads from "./components/uploads/uploads";
import Reports from "./components/reports/reports";
import POReport from "./components/reports/poreport";
import ProductionSchedule from "./components/reports/productionSchedule";
import DispatchInfo from './components/reports/dispatchInfo'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Damage from "./components/inventory/Damage";
import PlannedVsShortage from "./components/inventory/plannedVsShortage";
import OutofStockInventory from "./components/inventory/outofStockInventory";
import ApproachingOutofStock from "./components/inventory/approachingOutofStock";
import ShipmentsDeviations from "./components/LogisticsRisks/ShipmentsDeviations";
import ShipmentTracking from "./components/LogisticsRisks/ShipmentTracking";
import DeviationDays from "./components/LogisticsRisks/DeviationDays";
import Planning from "./components/pages/Planning";
import Productions from "./components/pages/Productions";
import PurchaseOrders from "./components/pages/PurchaseOrders";
import InTransit from "./components/pages/Inrtransit";
import CalendarComponent from './components/calendar/calendar'
import CustomerMasters from "./components/masters/CustomerMasters";
import SiteMasters from "./components/masters/SiteMaster";
import ContractorMaster from "./components/masters/ContractorMaster";
import MaterialMaster from "./components/masters/MaterialMaster";
import SupplierMaster from "./components/masters/SupplierMaster";
import SiteAdjustments from "./components/masters/SiteAdjustments";
import SupplierInventory from "./components/pages/SupplierInventory";
import ImpactedWorks from "./components/qaRisks/impactedWorks";
import ProductionRiskWorkSchedules from "./components/productionRisk/WorkSchedules";
import planningLedger from "./components/calendar/planningLedger";
import ProductionRiskLedger from "./components/productionRisk/productionRiskLedger";
import SupplierPo from "./components/SupplierPurchaseOrder/SupplierPo";
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login}></Route>
          <Route exact path="/login" component={Login}></Route>
          <div class="wrapper">
            <TopHeader />
            <div className="content myappcontent">
              <div className="container-fluid">
                <Route exact path="/dashboard" component={Dashboard}></Route>
                <Route exact path="/inwardgoods" component={Inward}></Route>
                <Route exact path="/goodsissuance" component={GoodsIssuance}></Route>
                <Route exact path="/outwardgoods" component={Outwards}></Route>
                <Route exact path="/inventory" component={Inventory}></Route>
                <Route exact path="/damagedInventory" component={DamagedInventory}></Route>
                <Route exact path="/outwardReturns" component={OutwardReturns}></Route>
                <Route exact path="/uploads" component={Uploads}></Route>
                <Route exact path="/reports" component={Reports}></Route>
                <Route exact path="/POReport" component={POReport}></Route>
                <Route exact path="/ProductionSchedule" component={ProductionSchedule}></Route>
                <Route exact path="/DispatchInfo" component={DispatchInfo}></Route>
                <Route exact path="/Damage" component={Damage}></Route>
                <Route exact path="/PlannedVsShortage" component={PlannedVsShortage}></Route>
                <Route exact path="/OutofStockInventory" component={OutofStockInventory}></Route>
                <Route exact path="/ApproachingOutofStock" component={ApproachingOutofStock}></Route>
                <Route exact path="/DeviationDays" component={DeviationDays}></Route>
                <Route exact path="/ShipmentsDeviations" component={ShipmentsDeviations}></Route>
                <Route exact path="/shipmentTracking" component={ShipmentTracking}></Route>


                <Route exact path="/planning" component={Planning}></Route>
                <Route exact path="/production" component={Productions}></Route>
                <Route exact path="/purchaseOrder" component={PurchaseOrders}></Route>
                <Route exact path="/intransit" component={InTransit}></Route>
                <Route exact path="/supplierPo" component={SupplierPo}></Route>


                <Route exact path="/customerMasters" component={CustomerMasters}></Route>
                <Route exact path="/siteMaster" component={SiteMasters}></Route>
                <Route exact path="/contractorMaster" component={ContractorMaster}></Route>
                <Route exact path="/materialMaster" component={MaterialMaster}></Route>
                <Route exact path="/supplierMaster" component={SupplierMaster}></Route>
                <Route exact path="/siteAdjustments" component={SiteAdjustments}></Route>

                <Route exact path="/calendarView" component={planningLedger}></Route>

                <Route exact path="/supplierInventory" component={SupplierInventory}></Route>
                <Route exact path='/impactedWorks' component={ImpactedWorks}></Route>
                {/* <Route exact path="/productionRiskWorkSchedules" component={ProductionRiskWorkSchedules}></Route> */}

                <Route exact path="/productionRiskWorkSchedules" component={ProductionRiskLedger}></Route>

              </div>
            </div>
            <TopFooter />
          </div>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
