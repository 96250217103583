import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import {
  productionRisksCalendar,
  getProductionScheduleLinkedToWorkId,
  supplierRecomendationForMaterial,
  getProductionSchedularRisk,
} from "../pages/const";
import { FormControlLabel, Switch, Tooltip,Typography,Grid } from "@mui/material";

const eventPropGetter = (event) => {
  const backgroundColor = event.risk || '#00933b'; // default color if no color is specified
  return { style: { backgroundColor } };
};

const MyEvent = ({ event }) => {
  return (
    <div
      style={{
        padding: "0",
        textAlign: "center",
        color: "black",
        height: "20px",
        "font-weight": "bold",
      }}
    >
      {event.deviationDays > 0 ? (
        <Tooltip
          title={
            <>
              <span>{event.deviationDays} Days Delayed.</span>
              <br />
              <span>
                ETA :{"  "}
                {new Date(event.taskEstimateDate).toLocaleDateString("en-GB")}
              </span>
            </>
          }
          placement="bottom-end"
        >
          <p>{event.title}</p>
        </Tooltip>
      ) : (
        <Tooltip
        title={
            <span>No Delay</span>
        }
        placement="bottom-end"
      >
        <p>{event.title}</p>
      </Tooltip>
      )}
    </div>
  );
};

const localizer = momentLocalizer(moment);


const ProductionRiskWorkSchedules = () => {
  const [view, setView] = useState(true);
  const [date, setDate] = useState(new Date());

  const [offcanvas, setoffcanvas] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [workEvents, setWorkEvents] = useState([]);
  const [selectedWorkEvent, setSelectedWorkEvent] = useState({});
  const [poList, setPoList] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [risks, setRisks] = useState([]);
  const [riskData, setRiskData] = useState();
  const history = useHistory();
  const [dataWiseMonthsDropdown, setDataWiseMonthsDropdown] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [siteId, setSiteId] = useState(sessionStorage.getItem("siteId"));
  const [siteName, setSiteName] = useState(sessionStorage.getItem("siteName"));
  const [materialNameSelectedToViewRisks, setMaterialNameSelectedToViewRisks] =
    useState();

  const columns = [
    {
      name: "Material Name",
      selector: (row) => row.materialName,
      minWidth: "250px",
    },
    {
      name: "QTY",
      selector: (row) => row.quantity,
    },
    {
      name: "UOM",
      selector: (row) => row.uom,
    },

    {
      name: "NEED BY DATE",
      selector: (row) => moment(row.needByDate).format("DD-MMM-YYYY"),
    },
    {
      name: "PO NUMBER",
      selector: (row) => row.orderNumber,
      minWidth: "200px",
    },
    {
      name: "SUPPLIER",
      selector: (row) => row.supplierId,
    },
    {
      name: "DEVIATION DAYS",
      selector: (row) => row.leadDays,
      cell: row => (
        <>
            {row.leadDays}
            {row.leadDays > 0 &&
                <i
                    className="fa fa-info-circle"
                    style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                    onClick={() => riskMitigation(row)} // Make sure to use an arrow function here
                ></i>
            }


        </>
    ),
    },
  ];
  const riskMitigation = (data) => {
    setMaterialNameSelectedToViewRisks(data.materialName);
    supplierRecomendationForMaterial(data)
      .then((res) => {
        if (res.status === 200) {
          setRisks(res.data.data);
          // setPoList(res.data.data)
          // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
          // setShowLoader(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
    let riskPayLoad = {
      deviationDays: data.deviationDays,
      needByDate: data.needByDate,
    };
    getProductionSchedularRisk(riskPayLoad)
      .then((res) => {
        if (res.status === 200) {
          setRiskData(res.data.data);
          // setPoList(res.data.data)
          // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
          // setShowLoader(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEventClick = (event) => {
    setoffcanvas(true);
    setSelectedWorkEvent(event);
    getProductionScheduleLinkedToWorkId(event.title)
      .then((res) => {
        if (res.status === 200) {
          setPoList(res.data.data);
          // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
          // setShowLoader(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeSideOverlay = () => {
    setoffcanvas(false);
    setPopoverVisible(false);
    setRisks([]);
  };

  const handleBackClick = () => {
    history.push("/dashboard", { propActiveState: "productionRisks" });
  };
  const OnLoadApi = (type="Risks") =>{
    // setShowLoader(true)
    productionRisksCalendar({ siteId: siteId, type: type })
      .then((res) => {
        if (res.status === 200) {
          let workevents = res.data.calendarTasks.map((data) => {
            data.start = new Date(data.taskPlannedEndDate);
            data.end = new Date(data.taskPlannedEndDate);
            data.title = data.taskId;
            data.allDay = true;
            return data;
          });
          setWorkEvents(workevents);
          // setShowLoader(false)
          setDataWiseMonthsDropdown(res.data.months);
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    OnLoadApi("Risks")
  
  }, []);

  const handleMonthChange = (event) => {
    const { value } = event.target;
    const selectedDateObject = JSON.parse(value);
    const { month, year } = selectedDateObject;
    const newDate = new Date(year, month - 1, 1); //
    setCurrentDate(newDate);
  };

  
  const toggleView = () => {
    setView(!view)
    if(view){
      OnLoadApi("Both")
    }else{
      OnLoadApi("Risks")
    }
  };

  return (
    <div id="claenderView">
      <div class="row mb-2">
        <div class="col-sm-5">
          {/* <h2 class="m-0 text-dark">Work Shedules at risk, &nbsp; &nbsp;
                        <span style={{ fontSize: 13 }}>
                            <strong>Site: </strong> <span className='textHighlighter'>{siteName}</span>
                        </span>
                    </h2> */}
        </div>
        <div class="col-sm-3">
          <div class="input-group">
            {dataWiseMonthsDropdown.length == 0 ? (
              <div>
                <span
                  className="redcolor"
                  style={{ fontSize: 16, marginTop: 14 }}
                >
                  No Risks Reported
                </span>
              </div>
            ) : (
              <select
                class="form-control form-control-sm"
                name="farmer"
                onChange={handleMonthChange}
              >
                <option>Select Month</option>
                {dataWiseMonthsDropdown.length !== 0 &&
                  dataWiseMonthsDropdown.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.monthsMap}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
        <div className="col-1"></div>
        <div class="col-sm-3">
          {/* <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span> */}
        </div>
      </div>
      <div className="">
      <Grid container alignItems="center">
          <Grid item>
            <Typography
              variant="body1"
              style={{ fontSize: "14px", fontWeight: "500" }}
            >
              Show All
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch checked={view} onChange={toggleView} color="primary" />
              }
              label={null} // No label to avoid duplication
              style={{ margin: 0 }} // Remove margin for centering
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              style={{ fontSize: "14px", fontWeight: "500" }}
            >
              Show Risks
            </Typography>
          </Grid>
        </Grid>

        <Calendar
          messages={{
            previous: "Previous", // Customize the "Previous" button
            // Customize the "Next" button
          }}
          localizer={localizer}
          events={workEvents}
          tooltipAccessor={null}
          style={{ height: "70vh", fontSize: "14px" }}
          // eventPropGetter={eventStyleGetter}
          views={["month", "week", "day"]}
          // onSelectEvent={handleEventClick}
          date={currentDate}
          onNavigate={(date) => setCurrentDate(date)}
          selectable
          components={{
            event: MyEvent, // Use the custom event component
          }}
          eventPropGetter={eventPropGetter}
          onSelectSlot={(slotInfo) => console.log("Selected slot:", slotInfo)}
          eventOverlapAccessor={() => true} // Allows events to overlap
          eventOffsetAccessor={10} // Sets the vertical offset between overlapping events
        />
        {/* </div> */}
      </div>
      <div
        id="sideOverlay"
        className={`side-overlay ${offcanvas ? "open" : ""}`}
        style={{
          boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)",
          width: "75%",
        }}
      >
        <div className="overlay-content">
          <i
            class="fas fa-times-circle close-button"
            aria-hidden="true"
            onClick={closeSideOverlay}
            style={{ fontSize: 20 }}
          ></i>
          {/* <button className="close-button" >Close</button> */}
          <div
            className="row"
            style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}
          >
            <div className="col-md-12">
              <div className="card-body">
                <div className="title row" style={{ justifyContent: "left" }}>
                  <h2 className="p-1 ml-1 m-2 text-dark">
                    Purchase requisitions Linked to work
                  </h2>
                </div>
                <div className="row mt-4 sideOverlaySummary">
                  <div className="col-4">
                    <strong>Work ID: </strong>
                    {poList.length > 0 && poList[0].workId}
                  </div>
                  <div className="col-3">
                    <strong>Work Start Date:</strong>{" "}
                    {poList.length > 0 &&
                      moment(poList[0].prodActualStartDate)
                        .subtract(1, "day")
                        .format("DD-MMM-YYYY")}
                  </div>
                  <div className="col-3">
                    <strong>Work End Date:</strong>{" "}
                    {poList.length > 0 &&
                      moment(poList[0].prodActualEndDate)
                        .subtract(1, "day")
                        .format("DD-MMM-YYYY")}
                  </div>
                </div>
                <div className="row mt-4" style={{ border: "1px solid black" }}>
                  <div className="col-md-12">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <DataTable
                          columns={columns}
                          data={poList}
                          pagination
                          //selectableRows
                          // selectableRowsComponent={BootyCheckbox}
                          // onSelectedRowsChange = {handleChange}
                          dense
                          selectableRowsHighlight="true"
                          compact
                          highlightOnHover="true"
                          striped
                        />
                      </div>
                    </div>
                    {risks.length > 0 && (
                      <>
                        <div className="row mt-4 pl-3">
                          <h2>
                            <strong>Materail Name : </strong>{" "}
                            {materialNameSelectedToViewRisks}
                          </h2>
                        </div>
                        <div className="row mt-4" style={{ fontSize: "15px" }}>
                          <div className="col-6">
                            <div
                              className="title row"
                              style={{ justifyContent: "center" }}
                            >
                              <h2 class="m-2 text-dark">Risk</h2>
                            </div>

                            {/* <div className="order-details">
                                                        <div className="detail-row">
                                                            <div className="detail-key">Order Confirmation Date</div>
                                                            <div className="detail-value">26/11/2023</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Lead Time</div>
                                                            <div className="detail-value">210 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Available Time - Installation</div>
                                                            <div className="detail-value">170 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Estimated Shipment Delay</div>
                                                            <div className="detail-value">40 days</div>
                                                        </div>
                                                    </div> */}

                            <tabel>
                              <tbody
                                className="table table-striped"
                                id="sideOverlaytable"
                              >
                                <tr>
                                  <td className="header">
                                    Production delay <small>(in Days)</small>{" "}
                                  </td>
                                  <td>
                                    {riskData && riskData.productionDelay}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="header">Need by date </td>
                                  <td>
                                    {riskData &&
                                      moment(riskData.needByDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="header">
                                    Logistics delay <small>(in Days)</small>
                                  </td>
                                  <td>{riskData && riskData.logisticsDelay}</td>
                                </tr>
                                <tr>
                                  <td className="header">
                                    ETA delay <small>(in Days)</small>
                                  </td>
                                  <td>{riskData && riskData.etaDelay}</td>
                                </tr>
                              </tbody>
                            </tabel>
                          </div>
                          <div
                            className="col-6"
                            style={{ borderLeft: "1px solid #666" }}
                          >
                            <div
                              className="title row"
                              style={{ justifyContent: "center" }}
                            >
                              <h2 class="m-2 text-dark">RISK MITIGATION</h2>
                            </div>
                            <p style={{ marginTop: 10 }}>
                              Recommended suppliers / Site to get on-time
                              delivery or delay less than current ETA
                            </p>
                            <ol
                              className="ml-0"
                              st9yle={{ lineHeight: 2, fontSize: 13 }}
                            >
                              {risks.map((item) => (
                                <li>
                                  <strong>{item.supplierName}</strong> - OnHand
                                  (
                                  {item.inventory != 0
                                    ? item.inventory + " EA"
                                    : item.inventory}
                                  ) -{" "}
                                  {item.recommendedSupplier
                                    .deviationDaysToNeedByDate === 0
                                    ? "On Time"
                                    : "Delay"}{" "}
                                  (
                                  {moment(
                                    item.recommendedSupplier
                                      .expectedDeliveryDate
                                  ).format("DD-MMM-YYYY")}
                                  )
                                </li>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionRiskWorkSchedules;
