import React, { useEffect, useState, useRef } from 'react';
const Productions = (props) => {
    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Productions</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                   
                </div>
            </div>
        </div>
    );
}

export default Productions;