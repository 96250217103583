import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
import DataTable from "react-data-table-component";
import { Tabs, Tab } from 'react-bootstrap-tabs';
const InwardList = (props) => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const [selectedDate, setSelectedDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const dateSelector = (date) => {
        setSelectedDate(date);
    }
    const fromdateSelector = (date) => {
        setFromDate(date);
    }
    const todateSelector = (date) => {
        setToDate(date);
    }
    const [QRcode, setQRcode] = useState('')
    const openQR = (state) => {
        setShow(true);
        setQRcode(state.target.id)
        //alert(JSON.stringify(state.target.id))
    }
    const columns = [
        {
            name: "QR Code",
            sortable: true,
            selector: "qrcode",
            minWidth: '80px',
            cell: row => <>
                <i class='fas fa-qrcode' style={{ fontSize: 20 }} onClick={openQR} id={row.qrcode}></i>
            </>
        },
        {
            name: "Supplier ID",
            selector: "supplierID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Supplier",
            selector: "supplierName",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material ID",
            selector: "materialID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material",
            selector: "materialName",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "QTY",
            selector: "quantity",
            sortable: true,
            minWidth: '60px'
        },
        {
            name: "UOM",
            selector: "uom",
            sortable: true,
            minWidth: '50px'
        },
        {
            name: "UID",
            selector: "uid",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "GRN #",
            selector: "grn",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "GRN Date",
            selector: "grnDate",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Truck #",
            selector: "truckNumber",
            sortable: true,
            minWidth: '150px'
        }
    ];
    const [data, setData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const onSubmit = (data, e) => {

        e.preventDefault();
        alert(JSON.stringify(data));
        // axios.post(env.produrl + '/entry/addinward', data)
        //     .then(res => {
        //         console.log(res.data.message);
        //         // alert(res.data.message)
        //         setShowResults(true);
        //         setResultMessage({
        //             error: false, message: res.data.message
        //         });
        //         e.target.reset();
        //     }).catch((err) => {
        //         console.log(err)
        //         setShowResults(true)
        //         setResultMessage({
        //             error: true, message: err.response.data.message
        //         });
        //         console.log(err.response.data.message);
        //     });
    }
    const _siteData = () => {
        axios.get(env.produrl + '/misc/sitemaster')
            .then(res => {

                // console.log(res.data.list);
                setSiteData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }

    const [show, setShow] = useState(false);
    const _supplierData = () => {
        axios.get(env.produrl + '/misc/suppliermaster')
            .then(res => {
                // console.log(res.data.list);
                setSupplierData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }
    const inwardList = () => {
        axios.get(env.produrl + '/entry/inwardList')
            .then(res => {
                setData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }
    useEffect(() => {
        inwardList();
        _siteData();
        _supplierData();
    }, [])


    return (
        <>
            <form>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>Construction Site</label>
                            <select class="form-control form-control-sm" name="farmer"
                                {...register("siteID", {
                                    // onChange: (e) => { onFarmerChange(e) }
                                })}
                            >
                                <option value="">-Select-</option>
                                {siteData.map(item => (
                                    <option
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.siteName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>Supplier</label>
                            <select class="form-control form-control-sm" name="farmer"
                                {...register("supplier", {
                                    required: "Please select Supplier",
                                    // onChange: (e) => { onFarmerChange(e) }
                                })}
                            >
                                <option value="">-Select-</option>
                                {supplierData.map(item => (
                                    <option
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.supplierName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>From Date</label>
                            <Datepicker
                                selected={fromDate}
                                onChange={fromdateSelector}
                                //showTimeSelect
                                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                placeholderText="From Date"
                                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                dateFormat="dd-MMM-yyyy"
                                name="fromDate"
                                className="form-control form-control-sm"
                            >
                            </Datepicker>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>To Date</label>
                            <Datepicker
                                selected={toDate}
                                onChange={todateSelector}
                                //showTimeSelect
                                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                placeholderText="To Date"
                                maxDate={new Date()}
                                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                dateFormat="dd-MMM-yyyy"
                                name="toDate"
                                className="form-control form-control-sm"
                            >
                            </Datepicker>
                        </div>
                    </div>
                </div>
            </form>
            <br></br>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
            <Modal show={show}>
                <Modal.Body>
                    <center>
                        <img src={QRcode} style={{ width: '60%' }} ></img>
                    </center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShow(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InwardList;