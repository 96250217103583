import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MaterialIcon from 'material-icons-react';
const TopHeader = (props) => {

  const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
  const [userName, setUserName] = useState(sessionStorage.getItem('partyType'));
  const [supplierCondition, setSupplierCondition] = useState(false);
  // const navigate = useNavigate();
  const logout = () => {
    sessionStorage.clear()
    window.location = '/login';

  }

  useEffect(() => {
    if (partyType === 'Supplier') {
      setSupplierCondition(true)
    }
  })

  return (
    <div>
      <nav className="main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">
        <div class="container-fluid" style={{ paddingLeft: 20, paddingRight: 20 }}>
          <NavLink exact to="/dashboard" class="navbar-brand">
            <img src="/dist/img/Collobrix_Dark.png" height="30" alt="RealCollab Logo"></img>&nbsp;
          </NavLink>
          <button
            class="navbar-toggler order-1"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse order-3" id="navbarCollapse">
            {!supplierCondition &&
              <ul class="navbar-nav navBar">
                <li class="nav-item">
                  <NavLink exact to="/dashboard" className="nav-link">
                    Dashboard
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/planning" className="nav-link">
                    Planning
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/PurchaseOrder" className="nav-link">
                    Purchase Order
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/ProductionSchedule" className="nav-link">
                    Production
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/ShipmentsDeviations" className="nav-link">
                    In Transit
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/inwardgoods" className="nav-link">
                    Inward Goods
                  </NavLink>
                </li>

                <li class="nav-item dropdown">
                  <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Outward</a>
                  <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                    <NavLink exact to="/goodsissuance" className="nav-link">
                      Goods Issue
                    </NavLink>
                    <NavLink exact to="/outwardgoods" className="nav-link">
                      Outward Goods
                    </NavLink>
                    <NavLink exact to="/outwardReturns" className="nav-link">
                      Outward Returns
                    </NavLink>
                  </ul>
                </li>
                {/* <li class="nav-item">
                  <NavLink exact to="/inventory" className="nav-link">
                    Inventory
                  </NavLink>
                </li> */}
                <li class="nav-item dropdown">
                  <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Inventory</a>
                  <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                    <NavLink exact to="/inventory" className="nav-link">
                      Site Inventory
                    </NavLink>
                    <NavLink exact to="/supplierInventory" className="nav-link">
                      Supplier Inventory
                    </NavLink>
                    <NavLink exact to="/damagedInventory" className="nav-link">
                      Damaged Inventory
                    </NavLink>
                    <a to="#" disabled className='disabled-link'>
                      VMI
                    </a>
                  </ul>
                </li>
                {/* <li class="nav-item">
                  <NavLink exact to="/damagedinventory" className="nav-link">
                    Damaged Inventory
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/Reports" className="nav-link">
                    Reports
                  </NavLink>
                </li> */}
                {/* {partyType === 'Contractor' &&
                  ( */}
                    <li class="nav-item">
                      <NavLink exact to="/uploads" className="nav-link">
                        Uploads
                      </NavLink>
                    </li>
                  {/* )
                } */}

                {partyType === 'Linesight' &&
                  (
                    <li class="nav-item dropdown">
                      <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Masters</a>
                      <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                        <NavLink exact to="/customerMasters" className="nav-link">
                          Customer Master
                        </NavLink>
                        <NavLink exact to="/siteMaster" className="nav-link">
                          Site Master
                        </NavLink>
                        <NavLink exact to="/contractorMaster" className="nav-link">
                          Contractor Master
                        </NavLink>
                        <NavLink exact to="/supplierMaster" className="nav-link">
                          Supplier Master
                        </NavLink>
                        <NavLink exact to="/materialMaster" className="nav-link">
                          Material Master
                        </NavLink>
                        {/* <NavLink exact to="/siteAdjustments" className="nav-link">
                          Site Adjustments
                        </NavLink> */}
                      </ul>
                    </li>
                  )
                }

              </ul>
            }
            {
              supplierCondition &&
              <ul class="navbar-nav navBar">
                < li class="nav-item">
                  <NavLink exact to="/supplierPo" className="nav-link">
                    Purchase Order
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/ProductionSchedule" className="nav-link">
                    Production Schedule
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/DispatchInfo" className="nav-link">
                    Dispatch Info
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/supplierInventory" className="nav-link">
                    Inventory
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink exact to="/uploads" className="nav-link">
                    Uploads
                  </NavLink>
                </li>
              </ul>
            }
          </div>

          <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">

            <li class="nav-item dropdown">
              <a class="nav-link alertIcon" data-toggle="dropdown" href="#">
                {/* <i class="far fa-bell"></i> */}
                <MaterialIcon icon='notifications_none' size={24} />
                <span class="badge badge-warning navbar-badge">15</span>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-header">15 Notifications</span>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> 4 new messages
                  <span class="float-right text-muted text-sm">3 mins</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer">
                  See All Notifications
                </a>
              </div>
            </li>
            <li className="loggedas">Logged as: </li>
            <li class="nav-item dropdown">
              <a class="nav-link loggedasButton" data-toggle="dropdown" href="#">
                {userName} <i class="fa fa-caret-down" aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                {/* <a href="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> View Profile
                  
                </a> */}
                <span href="#" class="dropdown-item" onClick={logout}>
                  <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav >
    </div >
  );
};
export default TopHeader;
