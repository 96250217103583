import { React, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import ShipmentInfo from "./ShipmentInfo";
import "react-bootstrap-accordion/dist/index.css";
import ShipmentDocuments from "./ShipmentDocuments";
import ShipmentExceptions from "./ShipmentExceptions";
import RouteMaps from "./RouteMaps";
import moment from "moment";
import { Stepper, Step, StepLabel, StepContent } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

export default function ShipmentTracking() {
//   const trackingEvents = {
//     events: [
//       {
//         eventName: "Empty Container Gate Out",
//         eventCode: "gateOutWithContainerEmpty",
//         estimateTime: "",
//         actualTime: "2024-02-02 12:00:00 AM",
//         location: "Panvel",
//         locationCode: "INPVL",
//         flightNumber: "",
//         locationType: "transhipment Port",
//       },
//       {
//         eventName: "Full Container Gate In",
//         eventCode: "gateInWithContainerFull",
//         estimateTime: "",
//         actualTime: "2024-02-05 12:00:01 AM",
//         location: "Jawaharlal Nehru",
//         locationCode: "INNSA",
//         flightNumber: "IB343A",
//         locationType: "origin Port",
//       },
//       {
//         eventName: "Load on Vessel",
//         eventCode: "loadOnVessel",
//         estimateTime: "",
//         actualTime: "2024-02-07 12:00:02 AM",
//         location: "Jawaharlal Nehru",
//         locationCode: "INNSA",
//         flightNumber: "IB343A",
//         locationType: "origin Port",
//       },
//       {
//         eventName: "Discharge from Vessel",
//         eventCode: "dischargeFromVessel",
//         estimateTime: "",
//         actualTime: "2024-02-19 12:00:03 AM",
//         location: "Port Louis, MAURITIUS",
//         locationCode: "MUPLU",
//         flightNumber: "MA342A",
//         locationType: "transhipment Port",
//       },
//       {
//         eventName: "Load on Vessel",
//         eventCode: "loadOnVessel",
//         estimateTime: "",
//         actualTime: "2024-02-26 12:00:04 AM",
//         location: "Port Louis, MAURITIUS",
//         locationCode: "MUPLU",
//         flightNumber: "MA342A",
//         locationType: "transhipment Port",
//       },
//       {
//         eventName: "Discharge from Vessel",
//         eventCode: "dischargeFromVessel",
//         estimateTime: "02-Apr-2024",
//         actualTime: "NA",
//         location: "New York",
//         locationCode: "USNYC",
//         flightNumber: "MA342A",
//         locationType: "destination Port",
//       },
//       {
//         eventName: "Full Container Gate Out",
//         eventCode: "gateOutWithContainerFull",
//         estimateTime: "02-Apr-2024",
//         actualTime: "NA",
//         location: "New York",
//         locationCode: "USNYC",
//         flightNumber: "MA342A",
//         locationType: "destination Port",
//       },
//       {
//         eventName: "Empty Container Gate In",
//         eventCode: "gateInWithContainerEmpty",
//         estimateTime: "02-Apr-2024",
//         actualTime: "NA",
//         location: "New York",
//         locationCode: "USNYC",
//         flightNumber: "MA342A",
//         locationType: "destination Port",
//       },
//     ],
//   };
const { state } = useLocation();
console.log("data is ....",state.state.data)
  const [trackingEvents,setEvents]=useState([])
  const token = sessionStorage.getItem("token_mayora");
  const getEvents = () => {
    axios
      .get(
        `https://api.generic.innovation.realware.tech/features/InSight/getTrackingEvents?trackingId=${state.state.data}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => setEvents(res.data.primary))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEvents();
  }, []);

  const [Delay, setDelay] = useState("");
  const delay = {
    days: [0],
    minutes: [0],
    hours: [0],
  };
  function formatDate(input) {
    // Validate the date string
    if (!input || typeof input !== "string" || input.trim() === "") {
      return <span style={{ fontStyle: "italic" }}>-NA-</span>;
    }

    // Regex pattern to validate the format "YYYY-MM-DD HH:MM:SS AM/PM"
    const pattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} (AM|PM)$/;

    if (!pattern.test(input)) {
      return <span style={{ fontStyle: "italic" }}>-NA-</span>;
    }

    // Use moment's string parsing capabilities with a format declaration
    let date = moment(input, "YYYY-MM-DD hh:mm:ss A");

    if (!date.isValid()) {
      return <span style={{ fontStyle: "italic" }}>-NA-</span>;
    }

    // Return the formatted date
    return date.format("DD-MMM-YYYY hh:mm:ss A");
  }
  const caluclateDate = (index) => {
    // console.log(latestEvents, "index")
    let time = 0;
    let date1, date2, date3, date4, date;

    if (index < trackingEvents.length) {
      if (
        trackingEvents[index].actualTime !== null &&
        trackingEvents[index].actualTime !== ""
      ) {
        if (
          trackingEvents[index + 1].actualTime !== null &&
          trackingEvents[index + 1].actualTime !== ""
        ) {
          date3 = formatDate(trackingEvents[index].actualTime) + "";
          date4 = formatDate(trackingEvents[index + 1].actualTime) + "";
          console.log(typeof date3, date3, "date3");
          date1 = new Date(date3);
          date2 = new Date(date4);
          date = Math.abs((date2 - date1) / (1000 * 3600 * 24));
          console.log(date, date1, date2, "Actual + Actual");
          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));

            // if (date < 1) return (Math.abs(Math.round(date * 100) / 100) * 100).toFixed(0) + ' Minutes'
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return ` 1 Minute`; //change done here last time

              if (hours == 0) {
                return `${minutes} Minutes`;
              }

              return `${hours} Hours ${minutes} Minutes`;
            }
            const hours = Math.floor(date);
            const totalMinutes = Math.round((date - hours) * 60);
            const minutes = totalMinutes % 60;

            const hoursText =
              hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""}` : "";
            const minutesText =
              minutes > 0 ? `${minutes} Minute${minutes > 1 ? "s" : ""}` : "";

            if (hoursText && minutesText) {
              return `${hoursText} ${minutesText}`;
            } else if (hoursText) {
              return hoursText;
            } else if (minutesText) {
              return minutesText;
            } else {
              return "Less than a minute";
            }
          }
          if (date <= 0) date = -1 * date;
          if (Math.round(date) == 1) return "1 Day";
          return Math.round(date) + " Days";
        } else if (
          trackingEvents[index + 1].estimateTime !== null &&
          trackingEvents[index + 1].estimateTime !== ""
        ) {
          date3 = formatDate(trackingEvents[index].actualTime) + "";
          date4 =
            formatDate(trackingEvents[index + 1].estimateTime) + "";
          date1 = new Date(date3);
          date2 = new Date(date4);
          console.log("Caluclating   actualtime and estimate", date1, date2);
          date = Math.abs((date2 - date1) / (1000 * 3600 * 24));
          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));
            console.log("Hours Are...", date);
            // if (date < 1) return (Math.abs(Math.round(date * 100) / 100) * 100).toFixed(0) + ' Minutes'
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return `${hours} Hours 1 Minute`;

              if (hours == 0) {
                return `${minutes} Minutes`;
              }

              return `${hours} Hours ${minutes} Minutes`;
            }
            const hours = Math.floor(date);
            const totalMinutes = Math.round((date - hours) * 60);
            const minutes = totalMinutes % 60;

            const hoursText =
              hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""}` : "";
            const minutesText =
              minutes > 0 ? `${minutes} Minute${minutes > 1 ? "s" : ""}` : "";

            if (hoursText && minutesText) {
              return `${hoursText} ${minutesText}`;
            } else if (hoursText) {
              return hoursText;
            } else if (minutesText) {
              return minutesText;
            } else {
              return "Less than a minute";
            }
          }
          if (date < 0) date = -1 * date;
          delay.days.push(Math.round(date));
          if (Math.round(date) == 1) return "1 Day";

          return Math.round(date) + " Days";
        } else {
          time = trackingEvents[index].actualTime;
          return time;
        }
      } else if (
        trackingEvents[index].estimateTime !== null &&
        trackingEvents[index].estimateTime !== ""
      ) {
        console.log(trackingEvents[index].estimateTime, "index");
        if (trackingEvents[index + 1].estimateTime !== null) {
          date3 = formatDate(trackingEvents[index].estimateTime) + "";
          date3 = new Date(date3);

          date4 =
            formatDate(trackingEvents[index + 1].estimateTime) + "";
          date4 = new Date(date4);
          // let a = date3 !== '' && date4 != '' ? date3 - date4 : 'NAN';
          console.log("Estimate and Estimate", date3, date4);
          date1 = new Date(date3);
          date2 = new Date(date4);
          date = Math.abs((date2 - date1) / (1000 * 3600 * 24));

          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));
            console.log("Hours Are...", date);
            // if (date < 1) return (Math.abs(Math.round(date * 100) / 100) * 100).toFixed(0) + ' Minutes'
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return `${hours} Hours 1 Minute`;

              if (hours == 0) {
                return `${minutes} Minutes`;
              }

              return `${hours} Hours ${minutes} Minutes`;
            }
            const hours = Math.floor(date);
            const totalMinutes = Math.round((date - hours) * 60);
            const minutes = totalMinutes % 60;

            const hoursText =
              hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""}` : "";
            const minutesText =
              minutes > 0 ? `${minutes} Minute${minutes > 1 ? "s" : ""}` : "";

            if (hoursText && minutesText) {
              return `${hoursText} ${minutesText}`;
            } else if (hoursText) {
              return hoursText;
            } else if (minutesText) {
              return minutesText;
            } else {
              return "Less than a minute";
            }
          }
          if (date < 0) date = -1 * date;
          delay.days.push(Math.round(date));
          if (Math.round(date) == 1) return "1 Day";

          return Math.round(date) + " Days";
        } else if (
          trackingEvents[index + 1].actualTime !== null &&
          trackingEvents[index + 1].actualTime !== ""
        ) {
          date3 = formatDate(trackingEvents[index].estimateTime) + "";
          date4 = formatDate(trackingEvents[index + 1].actualTime) + "";
          date1 = new Date(date3);
          date2 = new Date(date4);
          date = Math.abs((date3 - date2) / (1000 * 3600 * 24));
          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));
            console.log("Hours Are...", date);
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return `${hours} Hours 1 Minute`;

              if (hours == 0) {
                return `${minutes} Minutes`;
              }

              return `${hours} Hours ${minutes} Minutes`;
            }
            const hours = Math.floor(date);
            const totalMinutes = Math.round((date - hours) * 60);
            const minutes = totalMinutes % 60;

            const hoursText =
              hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""}` : "";
            const minutesText =
              minutes > 0 ? `${minutes} Minute${minutes > 1 ? "s" : ""}` : "";

            if (hoursText && minutesText) {
              return `${hoursText} ${minutesText}`;
            } else if (hoursText) {
              return hoursText;
            } else if (minutesText) {
              return minutesText;
            } else {
              return "Less than a minute";
            }
          }
          if (date < 0) date = -1 * date;
          if (Math.round(date) == 1) return "1 Day";
          return Math.round(date) + " Days";
        } else {
          time = formatDate(trackingEvents[index].estimateTime);
          console.log(time, "time");
          return time;
        }
      }
    }
    return <span>{time}</span>;
  };
  const history = useHistory();

  const closeTracking = () => {
    history.push("/ShipmentsDeviations");
  };
  return (
    <div className="farmMaster">
      <div class="row mb-2">
        <div class="col-sm-3">
          <h2 class="m-0 text-dark">Shipment Tracking</h2>
        </div>
      </div>
      <div class="row mb-2" style={{ marginTop: "20px" }}>
        <div class="col shipmentTracking">
          <h2 class="m-0 text-dark">
            HBL #:&nbsp;&nbsp;{" "}
            <span
              style={{
                backgroundColor: "#00ae39",
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "5px",
              }}
            >
              IZMA09589
            </span>
          </h2>
        </div>
        <div class="col shipmentTracking">
          <h2 class="m-0 text-dark">
            MBL #:&nbsp;&nbsp;{" "}
            <span
              style={{
                backgroundColor: "#145291",
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "5px",
              }}
            >
              MEDUUI944296
            </span>
          </h2>
        </div>
        <div class="col-sm-3 shipmentTracking">
          <h2 class="m-0 text-dark">
            FORWARDER REF #:&nbsp;&nbsp;{" "}
            <span
              style={{
                backgroundColor: "#555",
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "5px",
              }}
            >
              MEDU2488108
            </span>
          </h2>
        </div>
        <div className="col">
          <i
            class="fas fa-times-circle close-button"
            aria-hidden="true"
            onClick={closeTracking}
            style={{ fontSize: 20, top: 0 }}
          ></i>
        </div>
      </div>

      <div className="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4 shipmentTracking">
              <h2 class="m-0 text-dark">EVENTS</h2>
              <div
                style={{
                  maxHeight: "100vh",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                }}
              >
                <Stepper
                  activeStep={trackingEvents.length}
                  orientation="vertical"
                  sx={{
                    display: "flex",
                    border: "2px soild red",
                    paddingLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {trackingEvents.map((step, index) => (
                    <Step
                      key={index}
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color:
                            step.actualTime	 != null && step.actualTime	 == ""
                              ? "#FFBF00"
                              : "green",
                        },
                        "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                          {
                            color:
                              step.actualTime	 != null && step.actualTime	 == ""
                                ? "#FFBF00 !important"
                                : "green !important",
                          },
                      }}
                    >
                      <StepLabel>
                        <div
                          sx={{
                            fontSize: "12px !important",
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {step.eventName}
                        </div>
                        <div
                          sx={{
                            fontSize: "12px",
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          <span style={{ color: "#a7a5ad" }}>Location:</span>{" "}
                          {step.location}
                          
                        </div>
                        
                        <div
                          sx={{
                            fontSize: "12px",
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          <span style={{ color: "#a7a5ad" }}>
                            estimate Time:
                          </span>{" "}
                          {step.estimatedTime}
                        </div>
                        <div
                          sx={{
                            fontSize: "12px",
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          <span style={{ color: "#a7a5ad" }}>Actual Time:</span>{" "}
                          {step.actualTime}
                        </div>
                      </StepLabel>
                      {/* <StepContent>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "300px",
                              textTransform: "capitalize",
                            }}
                          >
                            {step.description}
                          </Typography>
                        </StepContent> */}
                      {/* {trackingEvents.length - 1 != index ?
                                                <Step>
                                                    <span style={{ fontSize: '12px', fontWeight: 'bold' ,}}>Duration:</span>&nbsp;&nbsp;
                                                    <span className="duration">
                                                        {
                                                            caluclateDate(index)

                                                        }

                                                    </span>

                                                </Step> : ''} */}
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            <div class="col-sm-8">
              <Tabs activeHeaderStyle={{ background: "transparent" }}>
                <Tab label="Shipment Info">
                  <ShipmentInfo />
                </Tab>
                <Tab label="Exceptions">
                  <ShipmentExceptions />
                </Tab>
                <Tab label="Shipment Documents">
                  <ShipmentDocuments />
                </Tab>
                <Tab label="Route Maps">
                  <RouteMaps />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
