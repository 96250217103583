import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Datepicker from "react-datepicker";
import axios from "axios";

export default function ShipmentsDeviations() {
  const selectedWorkId = sessionStorage.getItem("selectedWorkId");
  const [partyType, setPartyType] = useState(
    sessionStorage.getItem("partyType")
  );

  // const [data,setData]=useState([])
  const token = sessionStorage.getItem("token_mayora");
  const [data,setData]=useState([])
  const getIntransitList = async () => {
    try {
      const response = await axios.get(
        "https://api.generic.innovation.realware.tech/features/InSight/inTransit",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response Data:", response.data);
      setData(response.data.primary)
      return response.data; // Return the response data if needed
    } catch (error) {
      console.error("Error fetching in-transit list:", error);
      throw error; // Re-throw the error if you want to handle it further up the chain
    }
  };

  useEffect(() => {
    getIntransitList();
  }, []);

 
  const columns = [
    {
      name: "Tracking #",
      selector: "tracking#",
      sortable: true,
      minWidth: "150px",
      cell: (row) => (
        <a
          style={{
            width: "100%",
            lineHeight: 0.5,
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => shipmentTracking(row['tracking#'])}
        >
          {row['tracking#']}
        </a>
      ),
    },
    {
      name: "Order ID ",
      selector: "orderID",
      width: "200px",
      cell: (row) => (
        <a
          style={{
            width: "100%",
            lineHeight: 0.5,
            color: "blue",
            cursor: "pointer",
          }}
          // onClick={() => shipmentTracking(row)}
        >
          {row.orderID}
        </a>
      ),
    },
    {
      name: "Mode Of Transport",
      selector: "modeOfTransport",
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Supplier ID",
      selector: "supplierID",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Forwarder",
      selector: "forwarder",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Carrier",
      selector: "carrier",
      sortable: true,
      minWidth: "80px",
    },
    {
      name: "Shipment Site",
      selector: "shipmentSite",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "ETA",
      selector: "ETA",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Change In ETA",
      selector: "changeInETA",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Deviation Days",
      selector: "deviationDelay",
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Current Status",
      selector: "currentStatus",
      sortable: true,
      minWidth: "200px",
    },
  ];
  const history = useHistory();
  
  const shipmentTracking = (id) => {
    history.push("/shipmentTracking",{state:{data:id}});
  };
  const handleBackClick = () => {
    history.push("/dashboard", { propActiveState: "logisticRisks" });
  };
  return (
    <div className="farmMaster">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h2 class="m-0 text-dark">In Transit</h2>
          {/* <span style={{ fontSize: 13 }}>
                            <strong>Work ID: </strong> <span className='textHighlighter'>{selectedWorkId}</span>
                        </span> */}
        </div>

        <div class="col-6">
          <span style={{ display: "flex", justifyContent: "end" }}>
            <div className="btnBack" onClick={handleBackClick}>
              <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
            </div>
          </span>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-12 mb-0" style={{}}>
                <div className="row">
                  <div
                    className="col-md-1"
                    style={{ textAlign: "left", marginTop: 5 }}
                  >
                    <strong>Filter By: </strong>
                  </div>
                </div>
                <div className="row mb-0">
                  {(partyType === "Linesight" || partyType === "Supplier") && (
                    <div className="col-md-2">
                      <div className="form-group">
                        <select
                          class="form-control form-control-sm"
                          name="farmer"
                        >
                          <option value="">-Select Customer-</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {(partyType === "Customer" ||
                    partyType === "Contractor" ||
                    partyType === "Linesight") && (
                    <div className="col-md-2 mb-0">
                      <div className="form-group">
                        <select class="form-control form-control-sm" name="uom">
                          <option value="">-Select Site-</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="col-md-2 mb-0">
                    <Datepicker
                      //showTimeSelect
                      //setDuration(moment(closeDate).diff(batchSummary.startDate))
                      placeholderText="Select From Date"
                      //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                      dateFormat="dd-MMM-yyyy"
                      name="fromDate"
                      className="form-control form-control-sm"
                    ></Datepicker>
                  </div>
                  <div className="col-md-2 mb-0">
                    <Datepicker
                      placeholderText="Select To Date"
                      dateFormat="dd-MMM-yyyy"
                      name="closeDate"
                      className="form-control form-control-sm"
                    ></Datepicker>
                  </div>
                  <div className="col mb-0">
                    <button
                      style={{ marginTop: 0 }}
                      className="btn btn-sm btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="chips-container">
        <div key={React} className="chip" style={{ background: "pink" }}>
          All{" "}
        </div>
        <div key={React} className="chip">
          {" "}
          {" < 2 Days"}{" "}
        </div>
        <div key={React} className="chip">
          {" "}
          {">2 Days <1 Week"}{" "}
        </div>
        <div key={React} className="chip">
          {" "}
          {">1 Week - <15 Days"}{" "}
        </div>
        <div key={React} className="chip">
          {" "}
          {">15 Days - <1 Month"}{" "}
        </div>
        <div key={React} className="chip">
          {" "}
          {"> 1 Month"}{" "}
        </div>
        {/* <button className="close-btn" >x</button> */}
      </div>

      <div className="card">
        <div class="card-body">
          <div class="mt-2">
            <DataTable
              columns={columns}
              data={data}
              pagination
              selectableRowsHighlight="true"
              dense
              compact
              highlightOnHover="true"
              striped
            />
          </div>
        </div>
      </div>
    </div>
  );
}
