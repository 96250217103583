import React from 'react'
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
export default function Loader() {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    return (
        <div>
            <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                {indicatorEl} {/* renders only while loading */}
            </section> 
        </div>
    )
}
