import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import { inventoryUpload } from "../pages/const";
import Swal from 'sweetalert2';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const InventoryPreview = (tableData) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const [errorCount, setErrorCount] = useState();
    const [totalCount, setTotalCount] = useState();
    const columns = [
        {
            name: "Supplier ID",
            selector: row => row.supplierId,
            sortable: true,
        },
        {
            name: "Material Category",
            selector: row => row.materialCategory,
            sortable: true,

        },
        {
            name: "Material ID",
            selector: row => row.materialId,
            sortable: true,
        },
        {
            name: "Material Name",
            selector: row => row.materialName,
            sortable: true,
        },
        {
            name: "Material Description",
            selector: row => row.materialDescription,
            sortable: true,
        },
        {
            name: "Available Stock",
            selector: row => row.availableStock,
            sortable: true,
        },
        {
            name: "UOM",
            selector: row => row.uom,
            sortable: true,
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true,
        },
        {
            name: "Time",
            selector: row => row.time,
            sortable: true,
        },
        {
            name: "Valid",
            selector: row => row.errorExist ? 'NO' : 'YES',
            sortable: true,
        },
        {
            name: "Error",
            selector: row => row.error,
            sortable: true,
            minWidth: '250px'
        }
    ];
    const [data, setData] = useState([]);

    const customRowStyles = (row) => {
        // console.log("row.errorExist:", row.children[2][0].props.row.errorExist); // Log errorExist value
        return {
            color: row.children[2][0].props.row.errorExist === false ? 'inherit !important' : (row.children[2][0].props.row.errorExist === true ? 'red !important' : ''),
        }
    };

    useEffect(() => {
        console.log("tableData", typeof tableData.tableData[0]['errorExist']);
        if (tableData.tableData.length !== 0) {
            setData(tableData.tableData);
            const countWithErrorExist = data.filter(item => {
                // console.log("Processing item:", item.errorExist);
                return item.errorExist === true;
            }).length;
            const totalCount = data.length;
            setErrorCount(countWithErrorExist);
            setTotalCount(totalCount)
        }
    })

    const inventoryUploadSubmit = () => {
        setShowLoader(true);
        let obj = {
            data: data
        }
        console.log('obj', obj)
        inventoryUpload(obj).then(res => {
            if (res.status === 200) {
                setShowLoader(false);
                if (res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "success!",
                        // text: "You clicked the button!",
                        icon: "success"
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
            }
        })
    }

    return (
        <>
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Inventory</h2>
                </div>
                <div className='col-sm-6' style={{ display: "flex", justifyContent: "end" }}>
                    {errorCount !== 0 &&
                        <div className='mr-2' style={{ background: "red", borderRadius: "3px", padding: '5px', color: "white", fontWeight: "bold", margin: "1px" }}>
                            <strong>Errors : </strong> {errorCount}/{totalCount}
                        </div>
                    }
                    {/* {errorCount === 0 &&
                        <div className='mr-2' style={{ background: "green", borderRadius: "3px", padding: '5px', color: "white", fontWeight: "bold", margin: "1px" }}>
                            <strong>Valid : </strong> {errorCount}/{totalCount}
                        </div>
                    } */}
                    <input type="submit" onClick={inventoryUploadSubmit} disabled={errorCount !== 0} class="btn btn-sm btn-primary" />
                    {showLoader ? (
                        <section {...containerProps} style={{ marginTop: "0px", display: 'inline-block', verticalAlign: 'middle' }}>
                            {indicatorEl}
                        </section>
                    ) : (
                        ""
                    )}
                    {/* <input type="submit" onClick={inventoryUploadSubmit} value="Submit" class="btn btn-sm btn-primary" /> */}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        defaultSortField="batchID"
                        pagination
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                        customStyles={{
                            rows: {
                                style: customRowStyles
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default InventoryPreview;