// import React, { useEffect, useState } from "react";
// import { uploads } from "../pages/const";
// import { useLoading, Bars } from "@agney/react-loading";
// import Swal from "sweetalert2";
// import { useForm } from "react-hook-form";

// const ExcelUploader = ({ uploadType, filePath, responseDataSend, tabKey }) => {
//   const { containerProps, indicatorEl } = useLoading({
//     loading: true,
//     indicator: <Bars width="22" color="white" />,
//   });
//   const [showLoader, setShowLoader] = useState(false);
//   const [fileUploaded, setFileUploaded] = useState(false);
//   const [file, setFile] = useState(null);

//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors },
//   } = useForm();

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//     setFileUploaded(true);
//   };

//   const onSubmit = (data) => {
//     if (!fileUploaded) return;

//     setShowLoader(true);
//     const formData = new FormData();
//     formData.append("file", file);
//     formData.append("tier", data.tier);
//     formData.append("userName", data.userName);
//     formData.append("documentType", data.documentType);

//     uploads(formData, uploadType)
//       .then((res) => {
//         if (res.status === 200) {
//           responseDataSend(res.data);
//           setShowLoader(false);
//         } else {
//           Swal.fire({
//             title: "Oops...",
//             text: res.data.status.code,
//             icon: "error",
//           });
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     reset(); // reset form when tabKey changes
//     setFile(null);
//   }, [tabKey, reset]);

//   return (
//     <div className="excel-uploader mt-4">
//       <form
//         onSubmit={handleSubmit(onSubmit)}
//         className="mb-5"
//         style={{ marginTop: "8px" }}
//       >
//         <div className="row">
//           <div className="col-md-3">
//             <div className="form-group">
//               <label htmlFor="tier">Tier</label>
//               <select
//                 disabled
//                 id="tier"
//                 {...register("tier", { required: true })}
//                 className="form-control"
//               >
//                 <option value="">Select Tier</option>
//                 <option value="Tier 1">Tier 1</option>
//                 <option value="Tier 2">Tier 2</option>
//                 <option value="Tier 3">Tier 3</option>
//               </select>
//               {errors.tier && (
//                 <p style={{ color: "red" }}>This field is required</p>
//               )}
//             </div>
//           </div>

//           {/* UserName Dropdown */}
//           <div className="col-md-3">
//             <div className="form-group">
//               <label htmlFor="userName">UserName</label>
//               <select
//                 disabled
//                 id="userName"
//                 {...register("userName", { required: true })}
//                 className="form-control"
//               >
//                 <option value="">Select User</option>
//                 <option value="User 1">User 1</option>
//                 <option value="User 2">User 2</option>
//                 <option value="User 3">User 3</option>
//               </select>
//               {errors.userName && (
//                 <p style={{ color: "red" }}>This field is required</p>
//               )}
//             </div>
//           </div>

//           <div className="col-md-3">
//             <div className="form-group">
//               <a
//                 //   href={process.env.PUBLIC_URL + filePath}
//                 download
//                 style={{ color: "grey", cursor: "not-allowed", marginLeft: "7px" }}
//               >
//                 <i className="fa fa-download" aria-hidden="true"></i> Download
//                 Sample Template
//               </a>
//               <label htmlFor="file">.</label>
//               <input
//                 disabled
//                 type="file"
//                 accept=".xlsx,.xls,.xlxs"
//                 onChange={handleFileChange}
//                 className="form-control"
//                 style={{
//                   border: "1px solid black",
//                   borderRadius: "4px",
//                   padding: "5px",
//                 }}
//               />
//             </div>
//           </div>

//           {/* Document Type Dropdown */}
//         </div>
//         <div className="row">
//           {/* File Input */}

//           <div className="col-md-3">
//             <div className="form-group">
//               <label htmlFor="documentType">Document Type</label>
//               <select
//                 disabled
//                 id="documentType"
//                 {...register("documentType", { required: true })}
//                 className="form-control"
//               >
//                 <option value="">Select Document Type</option>
//                 <option value="Type 1">Type 1</option>
//                 <option value="Type 2">Type 2</option>
//                 <option value="Type 3">Type 3</option>
//               </select>
//               {errors.documentType && (
//                 <p style={{ color: "red" }}>This field is required</p>
//               )}
//             </div>
//           </div>

//           <div className="col-md-3">
//             <div className="form-group">
//               <button
//                 disabled
//                 type="submit"
//                 className="btn btn-sm primary-btn loggedasButton"
//                 //   disabled={!fileUploaded || showLoader}
//                 style={{ marginTop: "30px", marginLeft: "6%" }}
//               >
//                 {showLoader ? (
//                   <section
//                     {...containerProps}
//                     style={{
//                       marginTop: "0px",
//                       display: "inline-block",
//                       verticalAlign: "middle",
//                     }}
//                   >
//                     {indicatorEl}
//                   </section>
//                 ) : (
//                   "Preview"
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ExcelUploader;


import React, { useEffect, useState } from 'react';
import { uploads } from '../pages/const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import Swal from 'sweetalert2';


const ExcelUploader = ({ uploadType, filePath, responseDataSend, tabKey }) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    // const [excelData, setExcelData] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setFileUploaded(true);
    };

    const preview = () => {
        setShowLoader(true)
        const formData = new FormData();
        formData.append('file', file);
        uploads(formData, uploadType).then(res => {
            if (res.status === 200) {
                responseDataSend(res.data);
                setShowLoader(false)
            }
            else {
                Swal.fire({
                    title: "Oops...",
                    text: res.data.status.code,
                    icon: "error"
                })
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    useEffect(() => {
        setFile(null);
    }, [tabKey]);
    return (

        <div className="excel-uploader mt-4">
            <div>
                <a href={process.env.PUBLIC_URL + filePath} download style={{ color: 'blue', cursor: 'pointer', marginLeft: '7px' }}>
                    <i class="fa fa-download" aria-hidden="true"></i> Download Sample Template
                </a>
            </div>
            <div className="excel-uploader mb-5" style={{ marginTop: '8px' }}>
                <input type="file" accept=".xlsx,.xls,.xlxs" onChange={handleFileChange} style={{ border: "1px solid black", borderRadius: "4px", padding: "5px" }} />
                <button style={{ marginTop: "-4px", marginLeft: "6%" }} className="btn btn-sm promary-btn loggedasButton"
                    disabled={!fileUploaded || showLoader} onClick={() => preview()}>
                    {showLoader ? (
                        <section {...containerProps} style={{ marginTop: "0px", display: 'inline-block', verticalAlign: 'middle' }}>
                            {indicatorEl}
                        </section>
                    ) : (
                        "Preview"
                    )}

                </button>
            </div>
            {
                // showLoader ?
                //     <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                //         {indicatorEl} {/* renders only while loading */}
                //     </section> : ""
            }

            {/*  {excelData && (
                <div className="excel-preview">
                    //Render the Excel preview here
                    <pre>{excelData}</pre>
                </div>
          )}*/}
        </div >
    );
};

export default ExcelUploader;
