import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from 'react';

const MaterialMasterList = () => {

    const columns = [
        {
            name: "Material ID",
            selector: "materialID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Category",
            selector: "materialCategory",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Description",
            selector: "materialDescription",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Supplier ID",
            selector: "supplierID",
            sortable: true,
            minWidth: '60px'
        },
        {
            name: "Lead Days",
            selector: "leadDays",
            sortable: true,
            minWidth: '50px'
        }
    ];

    const [data, setData] = useState([
        {
            "materialID": "MTRL001",
            "materialName": "Framed Paintings",
            "materialCategory": "Wall Decorations",
            "materialDescription": "Framed Paintings",
            "supplierID": "supplier 1",
            "leadDays": "10 Days"
        },
        {
            "materialID": "MTRL001",
            "materialName": "Framed Paintings",
            "materialCategory": "Wall Decorations",
            "materialDescription": "Framed Paintings",
            "supplierID": "supplier 1",
            "leadDays": "10 Days"
        },
        {
            "materialID": "MTRL001",
            "materialName": "Framed Paintings",
            "materialCategory": "Wall Decorations",
            "materialDescription": "Framed Paintings",
            "supplierID": "supplier 1",
            "leadDays": "10 Days"
        },
        {
            "materialID": "MTRL001",
            "materialName": "Framed Paintings",
            "materialCategory": "Wall Decorations",
            "materialDescription": "Framed Paintings",
            "supplierID": "supplier 1",
            "leadDays": "10 Days"
        },
        {
            "materialID": "MTRL001",
            "materialName": "Framed Paintings",
            "materialCategory": "Wall Decorations",
            "materialDescription": "Framed Paintings",
            "supplierID": "supplier 1",
            "leadDays": "10 Days"
        },
    ]);

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
        </>
    )

}

export default MaterialMasterList;