import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import usaJson from './custom.geo.json'; // Path to your USA GeoJSON file
import { useHistory } from 'react-router-dom';
const USACQCMap = (props) => {
    console.log(props, "props")
    const [isMapReady, setIsMapReady] = useState(false);
    let history = useHistory(); // Initialize navigate function
    const onMarkerClick = (siteName, value, siteId) => {
        sessionStorage.setItem("siteId", siteId)
        history.push(`/${props.redirecto}?siteName=${encodeURIComponent(siteName)}&value=${encodeURIComponent(value)}&?siteId=${encodeURIComponent(siteId)}`);
    };

    // Define states with value > 10 for special coloring
    const specialValueStates = []; // Example, adjust based on your data


    const cityData = [
        { name: 'New York', state: 'New York', siteName: 'New York Datacenter', coord: ["-74.006", "40.7128"], risk: true, value: 10 },
        { name: 'San Francisco', state: 'California', siteName: 'San Francisco Datacenter', coord: ["-122.41940000", "37.77490000"], risk: false, value: 5 },
    ]

    //   const cityData = props.mapData
    const businessStates = cityData.map((item) => item.state)

    // Your existing data, modify to include 'color' property based on your logic
    const stateData = [
        { name: 'Alabama', value: 4822023 },
        { name: 'Alaska', value: 731449 },
        { name: 'Arizona', value: 6553255 },
        { name: 'Arkansas', value: 2949131 },
        { name: 'California', value: 38041430 },
        { name: 'Colorado', value: 5187582 },
        { name: 'Connecticut', value: 3590347 },
        { name: 'Delaware', value: 917092 },
        { name: 'District of Columbia', value: 632323 },
        { name: 'Florida', value: 19317568 },
        { name: 'Georgia', value: 9919945 },
        { name: 'Hawaii', value: 1392313 },
        { name: 'Idaho', value: 1595728 },
        { name: 'Illinois', value: 12875255 },
        { name: 'Indiana', value: 6537334 },
        { name: 'Iowa', value: 3074186 },
        { name: 'Kansas', value: 2885905 },
        { name: 'Kentucky', value: 4380415 },
        { name: 'Louisiana', value: 4601893 },
        { name: 'Maine', value: 1329192 },
        { name: 'Maryland', value: 5884563 },
        { name: 'Massachusetts', value: 6646144 },
        { name: 'Michigan', value: 9883360 },
        { name: 'Minnesota', value: 5379139 },
        { name: 'Mississippi', value: 2984926 },
        { name: 'Missouri', value: 6021988 },
        { name: 'Montana', value: 1005141 },
        { name: 'Nebraska', value: 1855525 },
        { name: 'Nevada', value: 2758931 },
        { name: 'New Hampshire', value: 1320718 },
        { name: 'New Jersey', value: 8864590 },
        { name: 'New Mexico', value: 2085538 },
        { name: 'New York', value: 19570261 },
        { name: 'North Carolina', value: 9752073 },
        { name: 'North Dakota', value: 699628 },
        { name: 'Ohio', value: 11544225 },
        { name: 'Oklahoma', value: 3814820 },
        { name: 'Oregon', value: 3899353 },
        { name: 'Pennsylvania', value: 12763536 },
        { name: 'Rhode Island', value: 1050292 },
        { name: 'South Carolina', value: 4723723 },
        { name: 'South Dakota', value: 833354 },
        { name: 'Tennessee', value: 6456243 },
        { name: 'Texas', value: 26059203 },
        { name: 'Utah', value: 2855287 },
        { name: 'Vermont', value: 626011 },
        { name: 'Virginia', value: 8185867 },
        { name: 'Washington', value: 6897012 },
        { name: 'West Virginia', value: 1855413 },
        { name: 'Wisconsin', value: 5726398 },
        { name: 'Wyoming', value: 576412 },
        { name: 'Puerto Rico', value: 3667084 }

        // Add other states
    ].map(state => {
        if (businessStates.includes(state.name)) {
            if (specialValueStates.includes(state.name)) {
                return { ...state, itemStyle: { color: '#fab32a' } }; // Special criteria met
            }
            return { ...state, itemStyle: { color: '#fab32a' } }; // Part of business states but doesn't meet special criteria
        }
        //return { ...state, itemStyle: { color: '#000' } }; // Not part of business states
    });



    useEffect(() => {
        // Assuming usaJson is static and imported directly, 
        // you might not need asynchronous loading here.
        // However, if you fetch it or perform any async operation, handle it inside.

        try {
            echarts.registerMap('USA', usaJson, {
                Alaska: {
                    left: -131,
                    top: 25,
                    width: 15
                },
                Hawaii: {
                    left: -110,
                    top: 28,
                    width: 5
                },
                'Puerto Rico': {
                    left: -76,
                    top: 26,
                    width: 2
                }
            });
            setIsMapReady(true); // Set the flag to true once the map is registered successfully.
        } catch (error) {
            console.error("Error registering map:", error);
            // Handle error scenario, maybe set state to show an error message instead.
        }
    }, []);

    const getOption = () => ({
        title: {
            text: '',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            showDelay: 0,
            transitionDuration: 0.2,

            formatter: function (params) {
                // Check if the tooltip is for a markPoint with city data
                if (params.data && params.data.name && params.data.siteName) {
                    // Display site name, value, and risk status
                    //return `${params.data.name} (${params.data.siteName}): Value - ${params.data.value}, Risk - ${params.data.risk ? 'High' : 'Low'}`;
                    return `${params.data.name} (${params.data.siteName})`;

                }
                // Fallback for other tooltip types
                return params.name;
            }

        },
        visualMap: {
            left: 'right',
            min: 500000,
            max: 38000000,
            orient: 'horizontal',
            top: 'bottom',
            show: false,
            inRange: {
                color: [
                    '#313695',
                    '#4575b4',
                    '#74add1',
                    '#abd9e9',
                    '#e0f3f8',
                    '#ffffbf',
                    '#fee090',
                    '#fdae61',
                    '#f46d43',
                    '#d73027',
                    '#a50026'
                ]
            },
            text: ['High', 'Low'],
            calculable: false
        },
        series: [
            {
                name: 'Risk Assessment',
                type: 'map',
                roam: false,
                map: 'USA',
                label: {
                    show: false, // Show the labels
                    formatter: '{b}', // Use the name of the area (state name) as the label
                    color: '#000', // Text color, adjust as needed
                    fontSize: 11, // Font size, adjust as needed
                    fontWeight: 'bold'
                },
                emphasis: {
                    label: {
                        show: true
                    }
                },
                itemStyle: {
                    normal: {
                        areaColor: '#c9c9c9', // Default color for all states
                        borderColor: '#777' // You can adjust the border color as needed
                    },

                    emphasis: {
                        areaColor: '#adadad', // Color on hover, if desired
                    }
                },
                data: stateData,
                markPoint: {
                    symbol: 'circle', // Shape of the marker, "circle" in this case
                    symbolSize: 15, // Size of the marker, adjust as needed
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b}', // Display the name of the markPoint
                        }
                    },
                    itemStyle: {
                        color: '#313796', // Color of the marker, adjust as needed
                    },

                    data: cityData.map(city => ({
                        ...city,
                        itemStyle: {
                            color: city.risk ? '#c80606' : '#00983d' // Conditional color based on risk
                        },
                        onclick: () => onMarkerClick(city.siteName, city.value),
                        label: {
                            show: true,
                            position: 'bottom',
                            color: 'black',
                            formatter: '{b}',
                            distance: 5,
                        }
                    })),

                }

            }
        ]
    });

    // Conditional rendering to handle loading state
    if (!isMapReady) {
        return <div>Loading map...</div>;
    }

    return (
        <div id='usmap' style={{ height: '500px', width: '100%' }}>
            <ReactECharts option={getOption()} style={{ height: '100%', width: '100%' }}
                onEvents={{
                    'click': (params) => {
                        console.log("params:::", params)
                        // Check if the click event is from a markPoint
                        if (params.componentType === 'markPoint') {
                            onMarkerClick(params.data.siteName, params.data.value, params.data.siteId);
                        }
                    }
                }} />
        </div>
    )
};

export default USACQCMap;
