import React from 'react';
import ReactEcharts from 'echarts-for-react';
const QaBar = (props) => {

    let options = {
        //color: ['#402d9f', '#02a8f4', '#8bc34a'],
        //color:[props.color],
        title: {
            text: 'Average delays in QA / QC - Installation : 8 days',
            left: 'right', // Position the title on the right side
            top: 14,
            paddingTop: 40,
            textStyle: {
                color: '#333', // Text color
                fontSize: 16, // Font size
                fontWeight: 'bold', // Font weight
                fontFamily: 'Arial, sans-serif', // Font family
                lineHeight: 1.5,
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "0%",
            },
        },
        legend: {
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            bottom: '0%'
        },
        grid: {

            left: 50,
            top: 50,
            right: 35,
            bottom: 30,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                splitLine: { show: false },
                data: ['Delay in FAT', 'Delay in Logistics', 'Pending goods issue', 'Awaiting delivery at the site', 'Pending installation by the supplier', 'Awaiting confirmation from Line Sight', 'Pending confirmation from the customer'],
                axisTick: {
                    alignWithLabel: true
                },
                axisPointer: {
                    type: 'shadow'
                },
                axisLabel: {
                    interval: 0,
                    rotate: 0, // Keep labels horizontal
                    formatter: function (value) {
                        // Break the label into multiple lines if it exceeds a certain length
                        const maxLength = 20; // Maximum character length per line
                        let result = '';
                        let currentLine = '';

                        value.split(' ').forEach((word, index) => {
                            if ((currentLine.length + word.length) >= maxLength) {
                                result += currentLine + '\n\n'; // Add the current line to the result and start a new line
                                currentLine = word;
                            } else {
                                currentLine += (currentLine.length > 0 ? ' ' : '') + word; // Add the word to the current line
                            }
                        });

                        if (currentLine) {
                            result += currentLine; // Add any remaining text in currentLine to the result
                        }

                        return result;
                    },
                    margin: 20,
                    // Adjust styling as needed
                },
                nameLocation: 'middle',
                nameGap: 30
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Installations Count',
                nameLocation: 'middle',
                nameGap: 50
            }
        ],
        series: [
            {
                name: 'Count',
                type: 'bar',
                barWidth: '15%',
                //stack: 'total',
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: [1, 1, 1, 3, 1, 4, 1]
            },


        ],
    };
    return (
        <div className="Temp">
            {/* {props.data.xAxis.length>0 ? */}
            <ReactEcharts
                option={options}
                style={{ height: "300px", width: "100%" }}
                opts={{ renderer: "svg" }}
            />
        </div>
    );
}
export default QaBar;