import React from 'react';
import { AiFillCaretDown } from "react-icons/ai";
import { TfiFiles, TfiTime, TfiTruck, TfiDashboard, TfiLayoutAccordionSeparated } from "react-icons/tfi";

const InfoBox = ({ iconName, title, number, total, onClick, isActive, desc }) => {
  // Dynamically select the icon based on iconName prop,
  const icons = {
    files: TfiFiles,
    time: TfiTime,
    truck: TfiTruck,
    dashboard: TfiDashboard,
    layout: TfiLayoutAccordionSeparated,
  };

  const IconComponent = icons[iconName];

  // Style functions specific to InfoBox
  const getBoxStyle = () => ({
    backgroundColor: isActive ? '#d2e2f8' : '#f1f1f1',
    cursor: 'pointer',
  });

  const getCaretStyle = () => ({
    display: isActive ? 'block' : 'none',
  });

  return (
    <div className="col">
      <div className="info-box" style={getBoxStyle()} onClick={onClick}>
        <span className="info-box-icon blue-bg elevation-1">
          {IconComponent ? <IconComponent color="#FFF" size={30} /> : null}
        </span>
        <div className="info-box-content">
          <span className="info-box-text">{title}</span>
          <span className="info-box-number">{number} <small>/{total}</small> </span>
          <span><small> {desc}</small></span>
        </div>
        <div className="caret" style={getCaretStyle()}>
          <AiFillCaretDown size={24} color="#d2e2f8" />
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
